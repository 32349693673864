@supports(padding:max(0px)) {
    body, header, footer {
        padding-left: min(0vmin, env(safe-area-inset-left));
        padding-right: min(0vmin, env(safe-area-inset-right));
    }
}

.body {
    background-color: #F3F3F3;
}

.berlinPhoto {
    background-image: url("../images/andyBerlin.jpg");
    height: 1000px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-attachment: fixed;
    margin: auto;
    font-family: sans-serif;
}

.heyIntro {
    padding-top: 60px;
    padding-left: 60px;
    color: white;
    font-size: 40px;
    font-weight: 300;
}

.heyIntroBG {
    background: rgba(0, 0, 0, 0.5);
}

.aboutMeParent {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 70px 80px;
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucia Grande", sans-serif;
    font-size: 20px;  
}

.aboutMe {
    font-size: 20px;
    width: 100%;
    max-width: 550px !important;
}

.aboutMeParent h1 h2 {
    padding-bottom: 10px;
}

.aboutMeQuote {
    max-width: 450px !important;
    padding-top: 40px;
}

.contactHeaderParent {
    background-image: url("../images/viewSantaCruzBeach.jpg");
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-attachment: fixed;
    margin: auto;
    font-family: sans-serif;
}

.contactHeader {
    padding: 60px 60px;
    text-align: center;
    margin: auto;
    color: white;
    font-size: 200px;
    font-size: 15vw;
    font-weight: 300;
}

.contactTextParent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    flex-wrap: wrap;
    padding: 70px 80px;
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucia Grande", sans-serif;
    font-size: 20px;  
}

.contactText {
    font-size: 20px;
    width: 100%;
    max-width: 950px !important;
}

.quoteBlock {
    text-align: center;
    margin: auto;
    width: 90%;
}

.resolution2020 {
    width: 85%;
    display: flex;
    margin: auto;
    height: auto;
}

.picsParent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pics {
    width: 25%;
    margin: auto;
    height: auto;
    /* display: flex; */
}

.bonusPics {
    width: 95%;
    margin: auto;
    height: auto;
    /* display: flex; */
}

.navBar {
     font-family: 'Akzidenz Grotesk'; 
     background-color: white;
}

.contentBlock {
    margin: auto;
    padding: 20px 80px;
    max-width: 950px;
    font-family: sans-serif;
}

footer {
    padding: 40px 30px;
    margin: auto;
    text-align: center;
    font-size: 15px;
    background-color: white;
}

@media only screen and (max-width: 600px) {
    .body {
        background-color: #F3F3F3;
    }
    
    .berlinPhoto {
        background-image: url("../images/andyBerlin.jpg");
        height: 500px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-attachment: scroll;
        font-family: sans-serif;
    }
    
    .heyIntro {
        padding-top: 10px;
        padding-left: 10px;
        color: white;
        font-size: 30px;
        font-weight: 300;
    }
    
    .heyIntroBG {
        background: rgba(0, 0, 0, 0.5);
    }
    
    .aboutMeParent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        padding: 10px 10px;
        font-family: "Helvetica Neue", Helvetica, Arial, "Lucia Grande", sans-serif;
        font-size: 15px;  
    }
    
    .aboutMe {
        font-size: 15px;
        width: 375px;
    }
    
    .aboutMeParent h2 {
        font-size: 30px;
        padding-bottom: 10px;
    }
    
    .aboutMeQuote {
        width: 375px;
        padding-top: 5px;
        font-size: 15px;
    }
    
    .contactHeaderParent {
        background-image: url("../images/viewSantaCruzBeach.jpg");
        height: 500px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-attachment: scroll;
        margin: auto;
        font-family: sans-serif;
    }
    
    .contactHeader {
        padding: 5px 5px;
        text-align: center;
        margin: auto;
        color: white;
        font-size: 200px;
        font-size: 15vw;
        font-weight: 300;
    }
    
    .contactTextParent {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        padding: 10px 10px;
        font-family: "Helvetica Neue", Helvetica, Arial, "Lucia Grande", sans-serif;
        font-size: 15px;  
    }

    .contactText {
        font-size: 10px;
        width: 60%;
        /* max-width: 350px !important; */
    }
    
    .quoteBlock {
        text-align: center;
        margin: auto;
        width: 60%;
    }
    
    .resolution2020 {
        width: 65%;
        display: flex;
        margin: auto;
        height: auto;
    }
    
    .picsParent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .pics {
        width: 90%;
        margin: auto;
        height: auto;
        /* display: flex; */
    }

    .viewSF {
        width: 80%;
        margin: auto;
        height: auto;
        /* display: flex; */
    }
    
    .bonusPics {
        width: 95%;
        /* margin: auto; */
        height: auto;
        /* display: flex; */
    }
    
    .navBar {
         font-family: 'Akzidenz Grotesk'; 
         background-color: white;
    }
    
    .contentBlock {
        margin: auto;
        padding: 5px 20px;
        max-width: 950px;
        font-family: sans-serif;
    }
    
    footer {
        padding: 40px 30px;
        margin: auto;
        text-align: center;
        font-size: 15px;
        background-color: white;
    }
    
  }